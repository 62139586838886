import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { environment } from '@environments/environment';
import { MaterialModule } from 'src/app/material.module';

@Component({
  selector: 'cgw-social-media',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  templateUrl: './social-media.component.html',
  styleUrl: './social-media.component.scss',
})
export class SocialMediaComponent {
  public svgIcons = environment.svgIcons;

  redesSociales = [
    { nombre: 'X', icono: 'x', link: 'https://x.com/CompraGamerOK', fill: '' },
    {
      nombre: 'Instagram',
      icono: 'instagram',
      link: 'https://www.instagram.com/compragamer_oficial/',
      fill: '',
    },
    {
      nombre: 'Facebook',
      icono: 'facebook',
      link: 'https://www.facebook.com/compragamer',
      fill: '',
    },
    {
      nombre: 'Youtube',
      icono: 'youtube',
      link: 'https://www.youtube.com/c/CompraGamer',
      fill: '',
    },
    {
      nombre: 'Linkedin',
      icono: 'linkedin',
      link: 'https://www.linkedin.com/company/newton-station-srl/mycompany/',
      fill: '',
    },
    {
      nombre: 'Tiktok',
      icono: 'tiktok',
      link: 'https://www.tiktok.com/@compragamer.com?lang=es',
      fill: '',
    },
    { nombre: 'Twitch', icono: 'twitch', link: 'https://www.twitch.tv/compragamerok', fill: '' },
  ];
}
