<form
  [formGroup]="searchFG"
  (ngSubmit)="onSearch()"
  class="searcher">
  <mat-form-field
    appearance="outline"
    class="search__input">
    <mat-label>Buscar productos</mat-label>
    <input
      matInput
      type="text"
      placeholder="Buscá por productos, marcas y categorías"
      autocomplete="off"
      formControlName="search"
      #searchInput />

    @if (_promosService.selectedLiquidation | async) {
      <mat-checkbox
        formControlName="inLiquidation"
        class="searcher__checkbox searcher__checkbox--desktop primary txt_caption d-none d-md-flex"
        >Solo en {{ (_promosService.selectedLiquidation | async).name }}</mat-checkbox
      >
    }
    <button
      mat-icon-button
      matSuffix
      type="button"
      (click)="appliedSearchValue ? onClear() : onSearch()"
      [attr.aria-label]="(appliedSearch$ | async) ? 'Limpiar busqueda' : 'Buscar'">
      <mat-icon>{{ (appliedSearch$ | async) ? 'cancel' : 'search' }}</mat-icon>
    </button>
  </mat-form-field>
  @if (_promosService.selectedLiquidation | async) {
    <mat-checkbox
      formControlName="inLiquidation"
      class="searcher__checkbox searcher__checkbox--mobile primary txt_caption d-flex d-md-none"
      >Solo en {{ (_promosService.selectedLiquidation | async).name }}</mat-checkbox
    >
  }
</form>
