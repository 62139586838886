import { trigger, state, style, transition, animate } from '@angular/animations';

export const toggleDropdownAnimation = trigger('toggleDropdownAnimation', [
  state(
    'void',
    style({
      height: '0',
      opacity: 0,
      overflow: 'hidden',
    }),
  ),
  state(
    '*',
    style({
      height: '*',
      opacity: 1,
      overflow: 'hidden',
    }),
  ),
  transition(':enter', [
    style({ overflow: 'hidden' }),
    animate('250ms cubic-bezier(0.175, 0.885, 0.32, 1.275)'),
  ]),
  transition(':leave', [
    animate(
      '200ms ease-out',
      style({
        height: '0',
        opacity: 0,
        overflow: 'hidden',
      }),
    ),
  ]),
]);
