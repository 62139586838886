import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  private showMenu = new BehaviorSubject<boolean>(false);
  public showMenuObs$: Observable<boolean> = this.showMenu.asObservable();

  public switchValueMenu(): void {
    this.showMenu.next(!this.showMenu.getValue());
  }
}
