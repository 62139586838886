<div class="header py-2">
  <div class="container">
    <div class="row align-items-center justify-content-between">
      <!-- boton solo mobile -->
      <!-- logo -->
      <div class="col-xl-3 col-lg-4 col-md-3 col-6 order-2 d-flex align-items-center">
        <div class="d-lg-none mr-3">
          <cgw-action-button [data]="menuButton"></cgw-action-button>
        </div>
        <a
          class="d-flex align-items-center"
          [href]="'/'"
          (click)="navigateToIndex($event)">
          <img
            [src]="logoUrl"
            alt="Logo Compra Gamer"
            class="pointer logo" />
        </a>
      </div>
      <!-- searcher -->
      @if (showSearch) {
        <div
          class="col-xl-6 col-lg-6 col-12 order-md-3 order-5 search py-2"
          @toggleDropdownAnimation>
          <cgw-search [focusInput]="focusSearchInput$"></cgw-search>
        </div>
      }
      <!-- botonera -->
      <div class="col-xl-3 col-lg-2 col-auto order-4 d-flex justify-content-end align-items-center">
        <cgw-action-button
          class="d-block d-lg-none"
          [data]="searchButton">
        </cgw-action-button>
        @if (user | async) {
          <cgw-action-button
            class="d-block d-xl-none"
            [data]="accountButton"
            [matMenuTriggerFor]="menu"></cgw-action-button>
          <button
            class="account_dropdown d-none d-xl-flex align-items-center"
            mat-button
            [matMenuTriggerFor]="menu">
            <mat-icon iconPositionStart>person_outline</mat-icon>
            <span class="cg__fw-400 username">{{ userName | async }}</span>
            <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
          </button>
        } @else {
          <cgw-action-button [data]="userButton"></cgw-action-button>
        }

        <mat-menu
          #menu="matMenu"
          class="account_menu">
          <button
            class="buttonPrimary"
            mat-menu-item
            (click)="account()">
            <mat-icon>person_outline</mat-icon>Mi cuenta
          </button>
          <button
            class="buttonPrimary"
            mat-menu-item
            (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>Cerrar sesión
          </button>
        </mat-menu>
        <div class="cart_button">
          <cgw-action-button [data]="cartButton"></cgw-action-button>
          @if (countProducts | async) {
            <span (click)="cartButton.action()">
              {{ countProducts | async }}
            </span>
          }
        </div>
      </div>
    </div>
  </div>
</div>
