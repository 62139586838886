import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { APP_CONST } from '@core/constants/app.const';
import { filter, interval } from 'rxjs';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class AppUpdatesService {
  constructor(private _swUpdate: SwUpdate) {
    this.manageUpdateCheck();
    this.manageUpdateDetection();
  }

  public async checkForUpdates(): Promise<boolean> {
    return this._swUpdate.checkForUpdate();
  }

  private notifyUpdate(): void {
    NotificationService.showWithActions('Hay una nueva actualización disponible.', {
      actions: [
        {
          label: 'Actualizar',
          action: () => {
            document.location.reload();
          },
        },
      ],
      duration: null,
    });
  }

  private manageUpdateCheck(): void {
    if (!this._swUpdate.isEnabled) return;
    interval(APP_CONST.updateCheckDelay).subscribe(async () => {
      try {
        await this.checkForUpdates();
      } catch (error) {
        console.error('Error al comprobar actualizaciones', error);
      }
    });
  }

  private manageUpdateDetection(): void {
    this._swUpdate.versionUpdates
      .pipe(filter((evt: VersionReadyEvent) => evt.type === 'VERSION_READY'))
      .subscribe(() => {
        this.notifyUpdate();
      });
  }
}
