import { CommonModule } from '@angular/common';
import { Component, OnDestroy, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, Subscription } from 'rxjs';

@Component({
  selector: 'cgw-additional',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './additional.component.html',
  styleUrls: ['./additional.component.scss'],
})
export class AdditionalComponent implements OnDestroy {
  private routerSubscription$: Subscription;

  public showIcons8: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showWebFonts: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly showAtpc = signal<boolean>(false);
  public readonly showWarranties = signal<boolean>(false);

  constructor(private router: Router) {
    this.routerSubscription$ = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentUrl = this.router.url;
        const url = new URL(`https://compragamer.com${currentUrl}`);

        const isHomeRoute = url.pathname === '/';
        const isProductRoute = currentUrl.startsWith('/producto/');
        const isArmaTuPC = currentUrl.startsWith('/armatupc');
        const isCarrito = currentUrl.startsWith('/carro-compras');
        const isWarranties = currentUrl.startsWith('/garantias');

        this.showWebFonts.next(isArmaTuPC);
        this.showIcons8.next(isProductRoute || isArmaTuPC || isCarrito);
        this.showAtpc.set(isHomeRoute);
        this.showWarranties.set(isWarranties);
      });
  }
  public ngOnDestroy(): void {
    this.routerSubscription$?.unsubscribe();
  }
}
