<div class="navbar">
  <div class="navigation-toolbar">
    <div class="row justify-content-center align-items-center">
      <div class="container justify-content-center align-items-center d-flex">
        <!-- Contenido Desktop -->
        <div
          class="navigation-toolbar__desktop d-none d-lg-flex justify-content-center align-items-center row col-xl-6 col-lg-7 col-md-12 col-sm-12 col-12 my-2">
          @for (link of links; track $index) {
            <div class="cg__ty-h5 col-12 col-lg-3 justify-content-center d-flex cg__fw-500">
              <a
                [routerLink]="link.routerLink"
                [queryParams]="link.queryParams"
                [queryParamsHandling]="link.queryParamsHandling"
                [class.active]="link.isActive"
                [attr.aria-label]="link.ariaLabel">
                {{ link.label }}
              </a>
            </div>
          }
        </div>
      </div>

      <div class="navigation-toolbar__mobile d-lg-none d-flex align-items-center">
        @if (showMenu$ | async) {
          <nav
            class="navigation-toolbar__mobile__menu container pb-2 pt-1"
            @toggleDropdownAnimation>
            <ul class="row">
              @for (link of links; track $index) {
                <li>
                  <button
                    class="links_mobile py-4 d-flex justify-content-start"
                    mat-button
                    [routerLink]="link.routerLink"
                    [queryParams]="link.queryParams"
                    [queryParamsHandling]="link.queryParamsHandling"
                    (click)="toggleMenu()">
                    <a
                      class="txt_body"
                      [class.active]="link.isActive"
                      [attr.aria-label]="link.ariaLabel">
                      {{ link.label }}
                    </a>
                  </button>
                </li>
              }
            </ul>
          </nav>
        }
      </div>
    </div>
  </div>
</div>
