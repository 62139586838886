import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '@core/services/navigation.service';
import { PromosService } from '@core/services/promos.service';
import { SearchConfig } from '@core/services/search-engine/search.config';
import { LiquidationModel } from '../../../products/components/liquidation-section/components/liquidation-card/liquidation-card.model';
import { ChipsFiltrosService } from '@core/services/chips-filtros.service';
import { BehaviorSubject, combineLatest, delay, map, Subject, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'cgw-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    AsyncPipe,
    CommonModule,
    MatCheckbox,
    FormsModule,
  ],
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input() focusInput: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @ViewChild('searchInput') searchInput: ElementRef;
  private readonly _destroyS = new Subject<boolean>();
  public readonly searchFG = new FormGroup({
    search: new FormControl<string | null>(null),
    inLiquidation: new FormControl<boolean | null>(true),
  });
  public get searchFC(): AbstractControl<string, string> {
    return this.searchFG.get('search');
  }
  public get inLiquidationFC(): AbstractControl<boolean, boolean> {
    return this.searchFG.get('inLiquidation');
  }
  public readonly appliedSearch$ = this._activatedRoute.queryParamMap.pipe(
    map((queryParams) => queryParams.get(SearchConfig.searchProp)),
    tap((search) => {
      this.appliedSearchValue = !!search;
      this.searchFC.setValue(search);
    }),
  );
  public appliedSearchValue: boolean;
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _navigationService: NavigationService,
    private _chipsFiltrosService: ChipsFiltrosService,
    public _promosService: PromosService,
  ) {}

  public ngOnInit(): void {
    this.focusInput.pipe(delay(0), takeUntil(this._destroyS)).subscribe((r) => {
      if (r) {
        this.searchInput.nativeElement.focus();
      }
    });

    combineLatest([this._promosService.selectedLiquidation, this._activatedRoute.queryParamMap])
      .pipe(
        map(([liquidation, queryParams]) => {
          if (liquidation && queryParams.get(SearchConfig.liquidationProp)) {
            this.inLiquidationFC.setValue(true);
          }
          const searchQuery = queryParams.get(SearchConfig.searchProp);
          this.searchFC.setValue(searchQuery || null);
        }),
        takeUntil(this._destroyS),
      )
      .subscribe();
  }

  public onClickActionButton(appliedSearch: any): void {
    appliedSearch.pipe(take(1)).subscribe((search) => {
      if (!search) {
        this.onSearch();
      } else {
        this.onClear();
      }
    });
  }

  public onSearch(): void {
    this._chipsFiltrosService.resetFilters();
    this.navigateSearch(this.searchFC.value?.trim());
  }

  public onClear(): void {
    this.navigateSearch(null);
    this._chipsFiltrosService.resetFilters();
  }

  private navigateSearch(query: string | null = null): Promise<boolean> {
    const { view, liqui } = this._activatedRoute.snapshot.queryParams || null;
    return this._navigationService.navigate(['/productos'], {
      queryParams: {
        [SearchConfig.searchProp]: query || null,
        view,
        liqui: this.inLiquidationFC.value ? liqui : null,
      },
    });
  }

  public ngOnDestroy(): void {
    this._destroyS.next(true);
    this._destroyS.complete();
  }
}
