import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ActionButtonComponent } from 'src/app/home-page/components/action-button/action-button.component';
import { IActionButtonData } from 'src/app/home-page/components/action-button/IActionButtonData.model';
import { AppUpdatesService } from '@core/services/app-updates.service';
import { environment } from '@environments/environment';
import { NavigationService } from '@core/services/navigation.service';
import { CommonModule } from '@angular/common';
import { Routes } from '@core/enums/routes.enum';

@Component({
  selector: 'cgw-help',
  standalone: true,
  imports: [
    // Directives
    RouterLink,
    // Components
    ActionButtonComponent,
    // Modules
    CommonModule,
  ],
  templateUrl: './help.component.html',
  styleUrl: './help.component.scss',
})
export class HelpComponent {
  public get isExperimentalDev(): boolean {
    return environment.experimentalDev;
  }

  public helpButton: IActionButtonData = {
    type: 'quintary',
    title: 'Ayuda',
    action: (param?) => {
      this.navigationService.navigate(['/ayuda'], { queryParamsHandling: 'merge' });
    },
  };

  public repentanceButton: IActionButtonData = {
    type: 'sextarian',
    title: 'Botón de arrepentimiento',
    action: () => {
      this.navigationService.navigate([`/${Routes.DEVOLUCION}`], { queryParamsHandling: 'merge' });
    },
  };

  public checkForUpdatesBtn: IActionButtonData | null =
    this.isExperimentalDev ?
      {
        type: 'secondary',
        title: 'Comprobar actualización',
        action: () => {
          this._appUpdatesService.checkForUpdates();
        },
      }
    : null;

  constructor(
    private navigationService: NavigationService,
    private _appUpdatesService: AppUpdatesService,
  ) {}
}
