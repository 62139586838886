import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'cgw-afip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './afip.component.html',
  styleUrl: './afip.component.scss',
})
export class AfipComponent {}
