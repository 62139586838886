import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'cgw-us',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './us.component.html',
  styleUrl: './us.component.scss',
})
export class UsComponent {
  @Input() hideIcon: boolean = false;
  @Input() hideLink: boolean = false;

  public gptwLogo = environment.apiImageStatic + '/assets/logos/gptw.svg';
  public linkTrabajo: string = 'https://compragamer.hiringroom.com/jobs';
}
