<div
  class="us d-flex justify-content-center flex-column align-items-center"
  style="height: 100%">
  <img
    class="us__img {{ hideIcon ? 'hide' : '' }}"
    src="{{ gptwLogo }}"
    alt="great place to work" />
  <a
    class="us__link cg__fw-500 {{ hideLink ? 'hide' : '' }}"
    href="{{ linkTrabajo }}"
    target="_blank"
    >¡Trabajá con nosotros!</a
  >
</div>
