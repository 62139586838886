<div
  class="d-flex align-items-center flex-column social justify-content-center"
  style="height: 100%">
  <p class="social__title txt_additional cg__fw-600">Seguinos en</p>
  <div class="social__socialMediaIcons">
    @for (red of redesSociales; track $index) {
      <!-- [aria-label]="'link red social ' + red.nombre" -->
      <a
        class="social__socialMediaIcons__icon p-2 d-flex"
        [href]="red.link"
        target="_blank">
        <mat-icon
          [svgIcon]="red.icono"
          [style.fill]="red.fill"></mat-icon>
      </a>
    }
  </div>
</div>
