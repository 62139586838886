<p class="align-items-center justify-content-center txt_legals notaPie p-2">
  Las marcas y logos de compragamer.com compragamer.com/tv compragamer.com/reviews son Propiedad de
  Newton Station SRL.<br />Todos los derechos reservados 2017.
  @if (showIcons8 | async) {
    Icons by <a href="https://icons8.com/">Icons8</a>
  }
  @if (showAtpc()) {
    Foto de
    <a
      href="https://unsplash.com/es/@na_creates?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
      >Nassim Allia</a
    >
    en
    <a
      href="https://unsplash.com/es/fotos/altavoz-negro-y-rojo-con-luz-azul-YZmbiNkvpBM?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
      >Unsplash</a
    >
  }
  @if (showIcons8 | async) {
    <span>
      . Icons made from <a href="https://www.onlinewebfonts.com/icon">svg icons </a>is licensed by
      CC BY 4.0
    </span>
  }
  @if (showWarranties()) {
    <a
      href="
      https://www.freepik.es/foto-gratis/flat-lay-hobby-estudio-concepto-trabajo-sobre-fondo-azul_22445518.htm#from_view=detail_alsolike"
      >Imagen de pvproductions en Freepik</a
    >
  }
</p>
