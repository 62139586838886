<div
  class="d-flex justify-content-center align-items-center"
  style="height: 100%">
  <a
    href="https://serviciosweb.afip.gob.ar/clavefiscal/qr/response.aspx?qr=2yBvcpJW9wS27AwRBCPy9A,,"
    target="_blank">
    <img
      style="height: 100px"
      src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg"
      alt="data fiscal" />
  </a>
</div>
