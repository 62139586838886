import { NavigationService } from '@core/services/navigation.service';
import { AdditionalComponent } from './components/additional/additional.component';
import { AfipComponent } from './components/afip/afip.component';
import { HelpComponent } from './components/help/help.component';
import { SocialMediaComponent } from './components/social-media/social-media.component';
import { UsComponent } from './components/us/us.component';
import { environment } from '@environments/environment';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cgw-footer',
  standalone: true,
  imports: [
    AdditionalComponent,
    AfipComponent,
    HelpComponent,
    SocialMediaComponent,
    UsComponent,
    CommonModule,
  ],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(public navigationService: NavigationService) {}
  public svgIcons = environment.svgIcons;
}
