import { Component, effect, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '@core/services/navigation.service';
import { environment } from '@environments/environment';
import { DialogModalComponent } from '@core/components/dialog-modal/dialog-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription, BehaviorSubject, take, filter } from 'rxjs';
import { UserService } from '@core/services/userHandler/user.service';
import { MaterialModule } from 'src/app/material.module';
import { CommonModule } from '@angular/common';
import { SearchComponent } from '@core/components/search/search.component';
import { SearchConfig } from '@core/services/search-engine/search.config';
import { toggleDropdownAnimation } from '@core/animations/toggleDropdown.animation';
import { ActionButtonComponent } from 'src/app/home-page/components/action-button/action-button.component';
import { IActionButtonData } from 'src/app/home-page/components/action-button/IActionButtonData.model';
import { BootstrapQueryService } from 'src/app/home-page/services/bootstrap-query.service';
import { CoreService } from '@core/services/core.service';
import { ProductsService } from '@core/services/products.service';
import { ShoppingCartService } from 'src/app/shopping-cart/services/shoppingCart.service';

@Component({
  selector: 'cgw-header',
  standalone: true,
  imports: [ActionButtonComponent, MaterialModule, SearchComponent, CommonModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [toggleDropdownAnimation],
})
export class HeaderComponent implements OnDestroy, OnInit {
  private userSubscription$: Subscription;
  private bootstrapQuerySubscription$: Subscription;
  private cartCountSubscribe$: Subscription;
  public userName: BehaviorSubject<string> = new BehaviorSubject('');
  public user: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public logoUrl = `${environment.apiImageStatic}/assets/logos/newlogo_blanco.svg`;
  public countProducts: BehaviorSubject<number> = new BehaviorSubject(0);
  public cartButton: IActionButtonData = {
    icon: 'shopping_cart',
    type: 'tertiary',
    action: (param?) => {
      this.navigationService.navigateUrlWithPersistence(['/carro-compras']);
    },
  };
  public userButton: IActionButtonData = {
    icon: 'person_outline',
    type: 'tertiary',
    title: 'Ingresá',
    class: 'txt_button',
    action: (param?) => {
      this.dialog.open(DialogModalComponent, {
        width: '580px',
        height: 'auto',
        maxWidth: '100vw',
        disableClose: true,
        data: { type: 'login' },
      });
    },
  };
  public accountButton: IActionButtonData = {
    icon: 'person_outline',
    type: 'tertiary',
  };
  public menuButton: IActionButtonData = {
    icon: 'menu',
    type: 'tertiary',
    action: (param?) => {
      this.toggleMenu();
    },
  };

  public searchButton: IActionButtonData = {
    icon: 'search',
    type: 'tertiary',
    action: (param?) => {
      this.focusSearchInput$.next(true);
      this.toggleSearchInput();
    },
  };

  public showSearch: boolean = false;
  public focusSearchInput$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public toggleSearchInput(value?: boolean): void {
    this.showSearch = value ?? !this.showSearch;
    this.searchButton.icon = this.showSearch ? 'close' : 'search';
  }
  constructor(
    public navigationService: NavigationService,
    public dialog: MatDialog,
    private userService: UserService,
    public dialogRef: MatDialogRef<DialogModalComponent>,

    public shoppingService: ShoppingCartService,
    public coreService: CoreService,
    public bootstrapQueryService: BootstrapQueryService,
    public _productsService: ProductsService,
  ) {
    this.userSubscription$ = this.userService.userLog$.subscribe((user) => {
      if (user) {
        this.user.next(!!user);
        this.userName.next(`${user?.nombre} ${user?.apellido}`);
      } else {
        this.user.next(false);
      }
    });

    effect(() => {
      if (this._productsService.productsIdx()) {
        const countProducts = this.shoppingService.getTotalQuantity(
          this._productsService.productsIdx(),
        );

        this.countProducts.next(countProducts());
      }
    });
  }
  public ngOnInit(): void {
    this.bootstrapQuerySubscription$ = this.bootstrapQueryService
      .getQueries()
      .subscribe((query) => {
        this.toggleSearchInput(query == 'lg' || query == 'xl' || query == 'xxl');

        const isSmallScreen = query == 'xs' || query == 'sm' || query == 'md';
        this.userButton.title = isSmallScreen ? null : 'Ingresá';

        const buttonType = isSmallScreen ? 'quartary' : 'tertiary';
        this.userButton.type = buttonType;
        this.cartButton.type = buttonType;
        this.searchButton.type = buttonType;
        this.accountButton.type = buttonType;
        this.menuButton.type = buttonType;
      });
  }

  public account(): void {
    this.navigationService.navigateUrlWithPersistence(['/cuenta']);
  }

  public logout(): void {
    this.dialogRef = this.dialog.open(DialogModalComponent, {
      width: '345px',
      height: 'auto',
      maxWidth: '100vw',
      disableClose: true,
      data: { type: 'confirmacion_logout' },
    });
    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.userService.logOut();
        } else {
          return;
        }
      });
  }

  public ngOnDestroy(): void {
    this.userSubscription$?.unsubscribe();
    this.cartCountSubscribe$?.unsubscribe();
    this.bootstrapQuerySubscription$?.unsubscribe();
  }

  public toggleMenu(): void {
    this.coreService.switchValueMenu();
  }

  public navigateToIndex(event: Event): void {
    event.preventDefault();
    this.navigationService.navigateUrlWithPersistence(['/'], {
      queryParams: { cate: null, [SearchConfig.grouperProp]: null },
    });
  }
}
