<div
  class="helpContainer d-flex justify-content-center flex-column align-items-center"
  style="height: 100%">
  <cgw-action-button [data]="helpButton" />
  <cgw-action-button [data]="repentanceButton" />
  <a
    class="helpContainer__tyc cg__fw-500"
    routerLinkActive="is-active"
    [routerLink]="'/terminos-condiciones'"
    queryParamsHandling="merge"
    >Términos y condiciones</a
  >

  @if (isExperimentalDev) {
    <cgw-action-button [data]="checkForUpdatesBtn" />
  }
</div>
