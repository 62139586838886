import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Params,
  QueryParamsHandling,
  Router,
  RouterModule,
} from '@angular/router';
import { NavigationService } from '@core/services/navigation.service';
import { CoreService } from '@core/services/core.service';
import { Subscription } from 'rxjs';
import { toggleDropdownAnimation } from '@core/animations/toggleDropdown.animation';
import { MaterialModule } from 'src/app/material.module';

interface Ilink {
  label: string;
  routerLink: string;
  ariaLabel: string;
  isActive: boolean;
  queryParams: Params;
  queryParamsHandling?: QueryParamsHandling;
}
@Component({
  selector: 'cgw-navbar',
  standalone: true,
  imports: [MaterialModule, RouterModule, CommonModule],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [toggleDropdownAnimation],
})
export class NavbarComponent implements OnInit, OnDestroy {
  private routerSubscription$: Subscription;
  public links: Ilink[] = [
    {
      label: 'Productos',
      routerLink: '/productos',
      ariaLabel: 'botón productos',
      isActive: false,
      queryParams: { cate: null, marca: null, filtros: null, sort: null, liqui: null },
      queryParamsHandling: 'merge',
    },
    {
      label: 'Notebooks',
      routerLink: '/productos',
      ariaLabel: 'boton notebooks',
      isActive: false,
      queryParams: {
        cate: 58,
        marca: null,
        filtros: null,
        sort: null,
        criterio: null,
        alcance: null,
        agrup: null,
        liqui: null,
      },
      queryParamsHandling: 'merge',
    },
    {
      label: 'Armá tu PC',
      routerLink: '/armatupc',
      ariaLabel: 'botón arma tu pc',
      isActive: false,
      queryParams: { cate: null, marca: null, filtros: null, sort: null, liqui: null },
      queryParamsHandling: 'merge',
    },
    {
      label: 'Ayuda',
      routerLink: '/ayuda',
      ariaLabel: 'botón ayuda',
      isActive: false,
      queryParams: { cate: null, marca: null, filtros: null, sort: null, liqui: null },
      queryParamsHandling: 'merge',
    },
  ];
  public showMenu$ = this.coreService.showMenuObs$;

  constructor(
    public navigationService: NavigationService,
    private router: Router,
    private route: ActivatedRoute,
    private coreService: CoreService,
  ) {}

  public ngOnInit(): void {
    this.routerSubscription$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event.urlAfterRedirects;
        this.links.forEach((link) => {
          link.isActive = this.isLinkActive(link, currentUrl, this.route.snapshot.queryParams);
        });
      }
    });
  }

  public ngOnDestroy(): void {
    this.routerSubscription$?.unsubscribe();
  }

  public toggleMenu(): void {
    this.coreService.switchValueMenu();
  }

  private isLinkActive(link: Ilink, currentUrl: string, params: Params): boolean {
    const isRouterLinkActive = currentUrl.startsWith(link.routerLink);
    if (isRouterLinkActive) {
      const todosMenosElActualConMismoLink = this.links.filter(
        (item) => item != link && item.routerLink == link.routerLink,
      );

      const queryParams = Object.entries(link.queryParams || {}).filter(
        ([_, value]) => value !== null && value !== undefined,
      );
      const areQueryParamsActive =
        queryParams.length ?
          queryParams.every(([key, value]) => params[key] == value)
        : !Object.keys(params).some((key) =>
            todosMenosElActualConMismoLink.some((link) =>
              Object.keys(link.queryParams).some((k) => link.queryParams[k] == params[key]),
            ),
          );

      return areQueryParamsActive;
    } else {
      return false;
    }
  }
}
