<div class="footer">
  <div class="container">
    <div class="row align-items-stretch py-4">
      <cgw-afip class="col-6 col-xl-3 col-lg-2 py-2 mb-2 mb-lg-0"></cgw-afip>

      <cgw-us
        class="col-6 d-lg-none py-4 py-lg-2"
        [hideLink]="true">
      </cgw-us>

      <cgw-help class="col-xl-3 col-lg-4 py-4 py-lg-2"></cgw-help>

      <cgw-social-media class="col-xl-3 col-lg-3 py-4 py-lg-2"></cgw-social-media>

      <cgw-us
        class="col-xl-3 col-lg-3 py-lg-2 py-4"
        [hideIcon]="true">
      </cgw-us>
    </div>
  </div>
</div>
<cgw-additional></cgw-additional>
